<template>
  <div>
    <b-modal
      id="edit-cat-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="edit-cat-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('edit-cat-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Update Category</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="editLevel" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="image-upload w-100">
              <label for="cat-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="catImage"
                id="cat-image-input"
                class="mt-3"
                ref="catImageRef"
              />
            </div>
          </div>
          <div class="col-md-4 level-image-preview">
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else-if="catToEdit != null && catToEdit.image">
              <img :src="catToEdit.image" alt="Level image" />
            </template>
            <template
              v-else-if="catToEdit !== null && catToEdit.image === undefined"
            >
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-12">
            <label for="business-line">Business Line</label>
            <b-form-input
              required
              type="text"
              v-model="businessLineName"
              placeholder="Business Line Name"
              disabled
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12">
            <b-form-input
              required
              type="text"
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12">
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="input-row center">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
export default {
  props: {
    catToEdit: Object
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        businessLineId: null,
        categoryId: ""
      },
      businessLineName: "",

      imageUrl: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
      catImage: null,
      basedOptions: [
        { text: "Product Based", value: "product-based" },
        { text: "Vendor Based", value: "vendor-based" }
      ]
    };
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("edit-cat-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("edit-cat-modal");
    },
    uploadFile: function() {
      this.catImage = this.$refs.catImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.catImage);
    },
    resetForm: function() {
      const defaultForm = {
        name: "",
        description: "",
        categoryId: "",
        image: ""
      };
      this.imageUrl = null;
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.catImage = null;
      setTimeout(() => {
        this.$emit("editCatClosed");
      }, 100);
    },
    editLevel: function(e) {
      e.preventDefault();
      this.processingRequest = "processing";
      const tk = JwtService.getToken();
      this.form.token = `${tk}`;

      ApiService.post("/categories/category/edit", this.form).then(res => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);

          return;
        }
        if (res.data.success) {
          if (this.catImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.catImage);
            newFormData.append("categoryId", this.form.categoryId);
            newFormData.append("token", `${tk}`);
            ApiService.post("/categories/uploadImage", newFormData).then(
              res => {
                if (res.data.success === 0) {
                  this.hasError = "d-flex";
                  this.processingError = res.data.message;
                  this.processingRequest = "";
                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                } else {
                  this.processingRequest = "";
                  this.$emit("editedCat");
                }
              }
            );
          } else {
            this.processingRequest = "";
            this.$emit("editedCat");
          }
        }
      });
    }
  },
  mounted() {
    const val = this.catToEdit;
    if (val === null) return;
    this.form.categoryId = val.action.catId;
    this.form.businessLineId = val.action.businessLine._id;
    this.businessLineName = val.businessLine;
    this.form.name = val.name;
    this.form.description = val.description;
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .edit-cat-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      .level-image-preview {
        img {
          width: 100%;
          height: auto;
        }
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }
      .image-upload {
        label {
          display: flex;
          align-items: center;
          span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 32px;
              color: #000;
            }
            padding: 25px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
    }
  }
}
</style>
