<template>
  <div>
    <b-modal
      id="new-sub-cat-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-sub-cat-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-sub-cat-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Create SubCategory</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit.prevent="createSubCat" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="image-upload w-100">
              <label for="sub-cat-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="sub-cat-image-input"
                id="sub-cat-image-input"
                class="mt-3"
                ref="subCatImageRef"
                required
              />
            </div>
          </div>
          <div class="col-md-4 sub-category-image-preview">
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="SubCategory image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-12">
            <label for="business-line">Business Line</label>
            <b-form-input
              required
              type="text"
              v-model="businessLineName"
              placeholder="Business Line Name"
              disabled
            ></b-form-input>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-12">
            <label for="business-line">Category</label>
            <b-form-input
              required
              type="text"
              v-model="categoryName"
              placeholder="Category Name"
              disabled
            ></b-form-input>
          </div>
        </div>

        <div class="input-row row">
          <div class="col-12">
            <b-form-input
              required
              type="text"
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
        </div>
        <template v-if="businessLineName.toLowerCase() === 'food'">
          <div class="input-row row">
            <div class="col-12">
              <search-store-bar
                storePassed=""
                @sendStore="getStoreDetails"
                ref="validateStore"
              />
            </div>
          </div>
          <div class="input-row row">
            <div class="col-12">
              <multi-select
                :key="storeOptions.length"
                required
                v-model="form.stores"
                :options="storeOptions"
                :reduce-display-property="option => option.name"
                :reduce-value-property="option => option.id"
                no-options-text="No stores"
                selected-no-options-text="No stores selected"
              />
            </div>
          </div>
        </template>
        <div class="input-row row">
          <div class="col-12">
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="input-row center">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import SearchStoreBar from "@/view/layout/dashboard_components/SearchStoreBar";
import vMultiselectListbox from "vue-multiselect-listbox";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
export default {
  components: {
    "search-store-bar": SearchStoreBar,
    "multi-select": vMultiselectListbox
  },
  props: {
    businessLineName: String,
    categoryName: String,
    categoryId: String
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        categoryId: null,
        stores: []
      },
      subCategoryId: null,
      imageUrl: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
      subCatImage: null,
      fetchingStoreProducts: true,
      storeOptions: []
    };
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("new-sub-cat-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("new-sub-cat-modal");
    },
    uploadFile: function() {
      this.subCatImage = this.$refs.subCatImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.subCatImage);
    },
    closeForm: function() {
      this.$emit("newCatClosed");
    },

    getStoreDetails: function(obj) {
      if (obj !== null) {
        obj.forEach(item => {
          const { storeName, storeId } = item;
          if (
            this.storeOptions.map(store => store.id).indexOf(storeId) === -1
          ) {
            this.storeOptions = [
              ...this.storeOptions,
              { name: storeName, id: storeId }
            ];
          }
        });
      }
    },
    resetForm: function() {
      const defaultForm = {
        name: "",
        description: "",
        busiessline: null
      };
      this.imageUrl = null;
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.subCatImage = null;
      this.$emit("newCatClosed");
    },
    createSubCat() {
      if (
        this.businessLineName.toLowerCase() === "food" &&
        this.form.stores.length < 1
      ) {
        return;
      }
      this.form.categoryId = this.categoryId;
      this.processingRequest = "processing";
      const tk = JwtService.getToken();
      this.form.token = `${tk}`;

      ApiService.post("/subCategories/addSubCategory", this.form).then(res => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";

          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);

          return;
        }

        if (res.data.success) {
          console.log("ApiService.post | res", res);

          if (this.subCatImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.subCatImage);
            newFormData.append("subCategoryId", res.data.data._id);
            newFormData.append("token", `${tk}`);
            ApiService.setHeader("Content-Type", "multipart/form-data");
            ApiService.post("/subCategories/uploadImage", newFormData)
              .then(res => {
                console.log("ApiService.post | res", res.data);
                ApiService.setHeader("Content-Type", "application/json");
                if (res.data.success === 0) {
                  this.hasError = "d-flex";
                  this.processingError = res.data.message;
                  this.processingRequest = "";
                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                } else {
                  this.processingRequest = "";
                  this.$emit("createdNewSub");
                }
              })
              .catch(data => {
                console.log("ApiService.post | data", data);
                ApiService.setHeader("Content-Type", "application/json");
                this.hasError = "d-flex";
                this.processingError = data.message + " on " + data.url;
                this.processingRequest = "";
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
              });
          } else {
            this.processingRequest = "";

            this.$emit("createdNewSub");
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-sub-cat-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      .sub-category-image-preview {
        img {
          width: 100%;
          height: auto;
        }
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }
      .image-upload {
        label {
          display: flex;
          align-items: center;
          span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 32px;
              color: #000;
            }
            padding: 25px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
    }
  }
}
</style>
