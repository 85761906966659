<template>
  <div>
    <Categories
      v-if="showCategories"
      @displaySubCategories="displaySubCategories"
    />
    <SubCategories
      v-if="showSubCategories"
      @hideSubCategories="hideSubCategories"
      @displayCategories="displayCategories"
      @displaySubCategories="displaySubCategories"
      @displayProducts="displayProducts"
      :categoryId="categoryId"
      :categoryName="categoryName"
      :businessLine="businessLine"
    />
    <ProductsBySub
      v-if="showProducts"
      @displayCategories="displayCategories"
      @hideProducts="hideProducts"
      :subCategoryId="subCategoryId"
      :subCategoryName="subCatName"
      :categoryId="categoryId"
      :categoryName="categoryName"
      @displaySubCategories="displaySubCategories"
    />
  </div>
</template>

<script>
import Categories from "@/view/pages/categories/Categories";
import SubCategories from "@/view/pages/categories/SubCategories";
import ProductsBySub from "@/view/pages/categories/ProductsBySub";

export default {
  components: {
    Categories,
    SubCategories,
    ProductsBySub
  },
  data() {
    return {
      showCategories: true,
      showSubCategories: false,
      showProducts: false,

      categoryId: null,
      categoryName: null,
      businessLine: null,
      subCategoryId: null,
      subCatName: null
    };
  },
  methods: {
    displayCategories() {
      this.showCategories = true;
      this.hideProducts();
      this.hideSubCategories();
    },
    hideCategories() {
      this.showCategories = false;
    },
    displaySubCategories(options) {
      this.showSubCategories = true;
      this.hideCategories();
      this.hideProducts();
      if (options !== undefined) {
        const { id, name, businessLine } = options;
        this.categoryId = id;
        this.categoryName = name;
        this.businessLine = businessLine;
      }
    },
    hideSubCategories() {
      this.showSubCategories = false;
    },
    displayProducts({ id, name }) {
      this.showProducts = true;
      this.hideCategories();
      this.hideSubCategories();
      this.subCategoryId = id;
      this.subCatName = name;
    },
    hideProducts() {
      this.showProducts = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
